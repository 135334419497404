import Layout from "components/Layout";
import styles from "./index.module.css";

const Error404 = () => {
  return (
    <Layout disableNavigation>
      <div className={styles.root}>
        <p className={styles.error}>Page not found</p>
      </div>
    </Layout>
  );
};

export default Error404;
